export const AMOUNT_DEPOSIT_METHOD_CODE = {
    BA: 'BA',
    RQR: 'RQR',
    QP: 'QP',
    CT: 'CT',
    QR: 'QR',
    R: 'R',
    E: 'E',
    P2P: 'P2P',
    MC: 'MC',
    BQR: 'BQR',
    EA: 'EA',
    EA1: 'eWallet1',
    B: 'B',
};

// backend deposit option value
const DEPOSIT_METHOD_CODE = [
    { code: 'QP', name: 'quickpay' },
    { code: 'QR', name: 'qrpay' },
    { code: 'B', name: 'onlinetransfer' },
    { code: 'CT', name: 'crypto' },
    { code: 'R', name: 'reload' },
    { code: 'BQR', name: 'bankqr' },
    { code: 'E', name: 'expressDeposit' },
    { code: 'MC', name: 'mobileCard' },
    { code: 'BA', name: 'bankApps' },
    { code: 'EA', name: 'EwalletApp' },
    { code: 'CP', name: 'cardPayment' },
    { code: 'P2P', name: 'p2p' },
    { code: 'LINE', name: 'Line' },
    { code: 'CD', name: 'cashDeposit' },
    { code: 'WT', name: 'wireTransfer' },
    { code: 'TQR', name: 'trueMoneyQr' },
    { code: 'RQR', name: 'regionQr' },
];

// backend withdraw option value
const WITHDRAW_METHOD_CODE = [
    { code: 'B', name: 'bank transfer' },
    { code: 'CT', name: 'crypto' },
    { code: 'R', name: 'reload(E-Wallet)' },
];

export const CMS_BANK_IMAGE_PATH = '/cms/public/payment/icons/Bank';
export const CMS_PAYMENT_GATEWAY_IMAGE_PATH = '/cms/public/payment/icons/Payment_Gateway';

export const OTP_CONTACT_TYPE = {
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
    QQ: 'QQ',
    CRYPTO: 'CRYPTO',
    SMS_VERIFICATION_BY_MEMBER: 'SMS_VERIFICATION_BY_MEMBER',
    GOOGLE_OTP: 'GOOGLE_OTP',
    WECHAT: 'WECHAT',
    CRYPTO_EMAIL_AUTH: 'CRYPTO_EMAIL_AUTH',
    ZALO: 'ZALO',
    WHATSAPP: 'WHATSAPP',
    LINE: 'LINE',
    TELEGRAM: 'TELEGRAM',
    SKYPE: 'SKYPE',
    EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
    SMS_VERIFICATION: 'SMS_VERIFICATION',
    WITHDRAW_PIN: 'WITHDRAW_PIN',
};

export const OTP_VERIFICATION_TYPE = {
    BIND_AUTHENTICATOR: 'BIND_AUTHENTICATOR',
    REGISTRATION: 'REGISTRATION',
    EXTERNAL_REGISTRATION: 'EXTERNAL_REGISTRATION',
    RESET_PASSWORD: 'RESET_PASSWORD',
    AFF_RESET_PASSWORD: 'AFF_RESET_PASSWORD',
    USER_VERIFICATION: 'USER_VERIFICATION',
    UPDATE_MEMBER_INFO: 'UPDATE_MEMBER_INFO',
    CRYPTO_ADDRESS: 'CRYPTO_ADDRESS',
    AFF_CRYPTO_ADDRESS: 'AFF_CRYPTO_ADDRESS',
    WITHDRAWAL_BANK: 'WITHDRAWAL_BANK',
    WITHDRAWAL_CRYPTO: 'WITHDRAWAL_CRYPTO',
    AFF_WITHDRAWAL_CRYPTO: 'AFF_WITHDRAWAL_CRYPTO',
    WITHDRAWAL_EWALLET: 'WITHDRAWAL_EWALLET',
    BANK_DETAIL_BANK: 'BANK_DETAIL_BANK',
    BANK_DETAIL_CRYPTO: 'BANK_DETAIL_CRYPTO',
    BANK_DETAIL_EWALLET: 'BANK_DETAIL_EWALLET',
    SIX_PIN: 'SIX_PIN',
};

export const OTP_VERIFICATION_METHOD = {
    WHATSAPP: 'WHATSAPP',
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
    GA: 'GA',
};

export const MODULE_TYPE = {
    USER_VERIFICATION: 'USER_VERIFICATION',
    UPDATE_MEMBER_INFO: 'UPDATE_MEMBER_INFO',
    CRYPTO_ADDRESS: 'CRYPTO_ADDRESS',
    AFF_CRYPTO_ADDRESS: 'AFF_CRYPTO_ADDRESS',
    WITHDRAWAL_CRYPTO: 'WITHDRAWAL_CRYPTO',
    AFF_WITHDRAWAL_CRYPTO: 'AFF_WITHDRAWAL_CRYPTO',
    WITHDRAWAL_BANK: 'WITHDRAWAL_BANK',
    WITHDRAWAL_EWALLET: 'WITHDRAWAL_EWALLET',
    BIND_AUTHENTICATOR: 'BIND_AUTHENTICATOR',
    BANK_DETAIL_BANK: 'BANK_DETAIL_BANK',
    BANK_DETAIL_EWALLET: 'BANK_DETAIL_EWALLET',
    SIX_PIN: 'SIX_PIN',
    REGISTRATION: 'REGISTRATION',
};

export const POPUP_TYPE = {
    GA: 'GA',
    PIN_ENTRY: 'PIN_ENTRY',
    EDIT_VERIFY_AUTH: 'EDIT_VERIFY_AUTH',
    SIX_PIN_SETUP: 'SIX_PIN_SETUP',
};

export const GA_FIELD = {
    KEY: 'key',
    CODE: 'gaCode',
    OTP: 'otp',
};

export const UPDATE_MEMBER_INFO_FIELD = {
    EMAIL: 'email',
    PHONE: 'phone',
    OTP: 'otp',
    GA: 'gaCode',
};

export const CRYPTO_ADDRESS_FIELD = {
    COIN: 'coin',
    NETWORK: 'network',
    ADDRESS: 'address',
    OTP: 'otp',
    GA: 'gaCode,',
};

export const SIX_PIN_SETUP_FIELD = {
    NEW_PIN: 'newPin',
    CONFIRM_PIN: 'confirmPin',
    OTP: 'otp',
};

export const CONTACT_VERIFICATION_METHODS = {
    PHONE: ['PHONE', 'WHATSAPP'],
    EMAIL: ['EMAIL'],
};
